import React from 'react';
import { Container, Row, Col, Table} from 'react-bootstrap';
import { APIMatch, APIRound } from '../Types'
import { Match } from './Match';
import {LuckyLoserSequence} from './LuckyLoserSequence';

function NewPlayer({name, isFinal}: {name: string, isFinal: boolean}){
    const descrText = isFinal ? "Verlierer aus Halbfinale:" : "Lucky Loser aus Arena:"
    return (
        <div className="border match">
            <b>{descrText}</b> <br/>
            {name}
        </div>
    )
}
type playerType = {name: string}

function MatchSequence({lefttop, leftbot, curMatch, isFinal}: {lefttop:APIMatch|playerType, leftbot:APIMatch|playerType, curMatch:APIMatch, isFinal: boolean}){
    function matchPlayer(matchOrPlayer: APIMatch|playerType){
        if('games' in matchOrPlayer){
            return (<Match match={matchOrPlayer} index={1} />)
        }
        else{
            return <NewPlayer name={matchOrPlayer.name} isFinal={isFinal}/>
        }
    }
    return (
        <Row className="match-row">
            <Col className="match-col">
                {matchPlayer(lefttop)}
                {matchPlayer(leftbot)}
            </Col>
            <Col className="center-parent match-col">
                <Match match={curMatch} index={1} />
            </Col>
        </Row>
    )
}

export function Round({round, lastRound, luckyLoserNextRound, isFinal}: {round:APIRound, lastRound: APIRound|null, luckyLoserNextRound: number[]|null, isFinal: boolean}){
    function findLast(player: string){
        const res = lastRound!!.matches.filter(
            match => match.winner===player
        )
        if(res.length===0)
            return {'name': player}
        else
            return res[0]
    }
    if(round === undefined)
        return (<div>No data?</div>)
    if(lastRound === null){
        return (
            <div>
                {round.matches.map(
                    (match, index) => <Match match={match} index={index}/>
                )}
            </div>
        )
    }
    else {
        let matchContainers = [];
        var roundI = 0
        for (let i=0; i < round.matches.length+(luckyLoserNextRound?.length ?? 0); i=i+2){
            var firstSeq = null
            if(luckyLoserNextRound?.includes(i) ?? false){
                firstSeq = <LuckyLoserSequence name={null}/>
            }
            else{
                firstSeq = <MatchSequence lefttop={findLast(round.matches[roundI].player1)}   leftbot={findLast(round.matches[roundI].player2)} curMatch={round.matches[roundI]} isFinal={isFinal}/>
                roundI++
            }
            var secSeq = null
            if(i+1 < round.matches.length+(luckyLoserNextRound?.length ?? 0)){
                if (luckyLoserNextRound?.includes(i + 1) ?? false) {
                    secSeq = <LuckyLoserSequence name={null} />
                }
                else {
                    secSeq = <MatchSequence lefttop={findLast(round.matches[roundI].player1)} leftbot={findLast(round.matches[roundI].player2)} curMatch={round.matches[roundI]} isFinal={isFinal}/>
                    roundI++
                }
            }
            if(isFinal){
                matchContainers.push(<tr><td>{firstSeq}</td></tr>)
                matchContainers.push(<th><h1 className="text-center">Spiel um Platz 3</h1></th>)
                matchContainers.push(<tr><td>{secSeq}</td></tr>)
            }
            else{
                matchContainers.push(
                    <tr><td>
                        {firstSeq}
                        {secSeq}
                    </td></tr>
                )
            }
        }
        return (
            <Container fluid>
                <Table striped>
                    <tbody>
                        {matchContainers}
                    </tbody>
                </Table>
            </Container>
        )
    }
}