import { useParams } from "react-router-dom";
import { ServerData } from '../Types';
import { Alert } from "react-bootstrap";
import { Round } from './Round';
import { ROUND_NAMES } from "../constants";

interface RouteParams {
    roundId: string
}

export function Rounds({data, setRound}: {data: ServerData, setRound: number|null}){
    const params = useParams<RouteParams>();
    
    var selectedRound: number;
    if (setRound === null){
        selectedRound = parseInt(params.roundId)-1

        if(selectedRound>=data.tournament.rounds.length || selectedRound < 0) {
            window.location.href = "/"
            return (<Alert>Ne Du... kein Bock auf sowas...</Alert>)
        }
    }
    else
        selectedRound = setRound
    const isSemiFinal = selectedRound == ROUND_NAMES.length-2
    const isFinal = selectedRound == ROUND_NAMES.length-1
    const heading = isFinal ? "Finale" : ROUND_NAMES[selectedRound]
    return(
        <div>
            <h1 className="text-center">{heading}</h1>
            <Round round={data.tournament.rounds[selectedRound]} luckyLoserNextRound={isSemiFinal ? [] : data.tournament.luckyLoserPos[selectedRound+1]} lastRound={selectedRound-1 >= 0 ? data.tournament.rounds[selectedRound-1] : null} isFinal={isFinal}/>
        </div>
    );
};