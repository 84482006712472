import React from 'react'
import { Container, Alert } from 'react-bootstrap';

export function Banner({text, link}: {text:string|null, link:string|null}){
    if(text === null)
        return (<Container fluid></Container>)
    else {
        return (
            <Container id="banner"> 
                <Alert  variant="primary">
                    { link !== null &&
                        <p>Der Link zur aktuellen Partie ist <a href={link as string} target="_blank" rel="noreferrer">{link}</a></p>
                    } 
                    Wenn Sie gerade Ihre Partie beendet haben kann es bis zu einer Minute dauern, bis hier das Ergebnis angezeigt wird und Sie den Link für die nächste Runde erhalten.
                </Alert>
            </Container>
        )
    }
}