import React from 'react';
import { Container, Table } from 'react-bootstrap';
import { APIPlayer, APIRound } from '../Types';

export function ParticipantTable({rounds, players}: {rounds: APIRound[], players: APIPlayer[]}){
    if (rounds === undefined || players === undefined)
        return (<p>Es gibt leider noch keine Tabelle</p>)

    return (
        <Container>
            <Table>
                <thead>
                    <tr>
                        <th>Platz</th>
                        <th>Spieler</th>
                        <th>DWZ</th>
                    </tr>
                </thead>
                <tbody>
                {(players === undefined || players.length === 0) ?
                    <tr><td></td><td>Es gibt leider noch keine Startliste.</td><td></td></tr> :
                    players.sort((a:APIPlayer, b:APIPlayer) => (b.rating ?? 0)-(a.rating ?? 0))
                        .sort((a:APIPlayer, b:APIPlayer) => (a.place ?? 1e10)-(b.place ?? 1e10))
                        .map( player => (<tr className={player.isRegistered ? "isRegistered": ""}>
                            <td>{player.place}</td><td>{player.name}</td><td>{player.rating}</td></tr> ))
                }
                </tbody>
            </Table>
        </Container>
    )
}