import React from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { APIRound } from '../Types';
import {LinkContainer} from 'react-router-bootstrap'
import {ROUND_NAMES} from '../constants'


export function NavBar({name, rounds, toggleAdminPageActive}: {name: string|null, rounds: APIRound[]|null, toggleAdminPageActive: any}) {
    const items = []

    if(rounds!==null)
    for(let i=0; i<rounds.length && i<ROUND_NAMES.length ; i++){
        items.push(
            <LinkContainer to={'/round/' + (i+1)}>
                <NavDropdown.Item key={(i)}>
                    {ROUND_NAMES[i]}
                </NavDropdown.Item>
            </LinkContainer>
        )
    }

    return (
      <Navbar bg="dark" variant="dark" expand="lg" sticky="top">
        <LinkContainer to="/">
            <Navbar.Brand>
                1. WWOO
            </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
                <NavDropdown title="KO-Runden" id="collasible-nav-dropdown">
                    {items}
                </NavDropdown>
                <NavDropdown title="Tabelle" id="collasible-nav-dropdown">
                    <LinkContainer to="/tabelle">
                        <NavDropdown.Item>Rangliste</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/teilnehmer">
                        <NavDropdown.Item>Teilnehmerliste</NavDropdown.Item>
                    </LinkContainer>
                </NavDropdown>
                <Nav.Link href="https://www.schachklub-kelheim.de/weihnachtsopen/1-wwoo/arenen/" target="_blank">Arenen</Nav.Link>
                <Nav.Link href="https://www.schachklub-kelheim.de/weihnachtsopen/1-wwoo/" target="_blank">Infos</Nav.Link>
                <Nav.Link href="https://youtu.be/_gsac8zanpo" target="_blank">Live-Kommentierung (ab 16:15)</Nav.Link>

                {name==="admin" &&
                    <LinkContainer to="/admin">
                        <Nav.Link onClick={toggleAdminPageActive}>Admin-Seite</Nav.Link>
                    </LinkContainer>
                }
            </Nav>
        </Navbar.Collapse>
        
      </Navbar>
    )
  }