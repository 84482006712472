import React from 'react';
import Cookies from 'js-cookie';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import {DOMAIN } from './constants'
import { ServerData } from './Types';
import { NavBar } from './components/NavBar';
import { Banner } from './components/Banner';
import { AdminPage } from './components/admin/AdminPage';
import { ParticipantPage } from './components/ParticipantPage';
import { ParticipantTable } from './components/ParticipantTable';
import { LinkModal } from './components/LinkModal';
import { Rounds } from './components/Rounds';
import { Anmeldung } from './components/Anmeldung';
//import { SSL_OP_COOKIE_EXCHANGE } from 'constants';
import { Container, Alert } from 'react-bootstrap';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import { JsxElement } from 'typescript';
//import { Server } from 'http';

function getToken(): null|string{
  const queryString = window.location.search
  const params = new URLSearchParams(queryString)
  var token: null|string = params.get("token")
  if(token != null){
    Cookies.set("API_TOKEN", token, {expires: 7})
    window.location.href = window.location.pathname
  }
  else{
    const curCookie = Cookies.get("API_TOKEN")
    if(curCookie !== undefined)
      token = curCookie
  }
  return token
}

function InfoBox ({text}: {text: string}) {
    return (
        <Container>
            <Alert variant="info" >
                <div dangerouslySetInnerHTML={{__html: text}}/>
            </Alert>
        </Container>
    )
}

function App() {
    const [data, setData] = React.useState(null as null|ServerData)
    const [gameLink, setGameLink] = React.useState(null as null|string)
    const [adminPageActive, setAdminPageActive] = React.useState(false)  
    const toggleAdminPageActive = () => {adminPageActive ? setAdminPageActive(false) : setAdminPageActive(true)}
    
    const [newLink, setNewLink] = React.useState(false);

    const token = getToken()

    async function fetchData(){
        const requestHeader = new Headers()
        if(token != null){
            requestHeader.set('Authentication', token)
        }
        const fetchRes = await fetch(`${DOMAIN}/data`, {
            method: 'GET',
            headers: requestHeader
        });
        const res: ServerData = await fetchRes.json(); 
        //const jsonStr = '{"tournament":{"rounds":[{"matches":[{"games":[{"id":"799803","result":2},{"id":"677234","result":2},{"id":"Hc6FwQal","result":null}],"player1":"Tobias Schindler","player2":"A B","winner":"Tobias Schindler"},{"games":[{"id":"403276","result":1},{"id":"645048","result":2}],"player1":"C D","player2":"E F","winner":"C D"},{"games":[{"id":"980836","result":1},{"id":"954096","result":1},{"id":"402942","result":0},{"id":"265172","result":2},{"id":"681740","result":1},{"id":"923004","result":1},{"id":"935855","result":1},{"id":"459727","result":1},{"id":"208671","result":1},{"id":"486582","result":1},{"id":"303448","result":2}],"player1":"G H","player2":"I J","winner":"G H"},{"games":[{"id":"608530","result":0},{"id":"200188","result":0}],"player1":"K L","player2":"M N","winner":"M N"}],"startTime":"2020-12-13"}]},"name":"ABC DEF","info":"starting soon..."}'
        //const res = JSON.parse(jsonStr)
        
        setData(res)
        setGameLink(res.nextLink)
    }
    
    React.useEffect(() => {
        // startup
        fetchData()
        setInterval(fetchData, 5*60*1000)
    }, []);
    
    React.useEffect(() => {
        // startup
        setNewLink(true)
    }, [gameLink]);
    /*
    // Update to new round when it's available
    if (res.tournament.rounds.length !== activeRound) {
        setActiveRound(res.tournament.rounds.length-1)      
    }*/

    // Update if new link is available
    
    
    if(data === null)
        return (
            <div className="App">
                <Router>
                    <NavBar name={null} rounds={null} toggleAdminPageActive={toggleAdminPageActive}/>
                    <Alert variant="warning">
                        Leider konnten keine Daten von Server geladen werden.
                    </Alert>
                </Router>
            </div>
        );
    else {
        if(data === null) console.log("undefined");
        return (
            <Router>
                <NavBar name={data.name} rounds={data.tournament.rounds} toggleAdminPageActive={toggleAdminPageActive}/>
                
                {data.info !== null && data.info !== "" &&
                    <InfoBox text={data.info!}/>
                }
                <Switch>
                    <Route exact path="/">
                        <Rounds data={data} setRound={data.tournament.rounds.length-1} />
                    </Route>

                    <Route exact path="/admin">
                        <AdminPage token={token} data={data}/>
                    </Route>

                    <Route exact path="/anmeldung">
                        <Anmeldung />
                    </Route>
                    <Route path="/anmeldung/:lichessName">
                        <Anmeldung />
                    </Route>

                    <Route exact path="/teilnehmer">
                        <ParticipantPage players={data.tournament.players}/>
                    </Route>

                    <Route exact path="/tabelle">
                        <ParticipantTable rounds={data.tournament.rounds} players={data.tournament.players}/>
                    </Route>

                    <Route path={"/round/:roundId"}>
                        <Rounds data={data} setRound={null}/>
                    </Route>
                </Switch>

                {newLink &&
                    <LinkModal show={newLink} link={data.nextLink} onHide={() => setNewLink(false)}/>
                }   
                {data.name !== null &&
                    <Banner text={data.info} link={data.nextLink}/>
                }   
            </Router>
        );
    }
}

export default App;