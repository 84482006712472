import React from 'react';
import { Row, Col } from 'react-bootstrap';


export function LuckyLoserSequence({name}: {name: string|null}){
    return (
        <Row className="lucky-loser-row">
            <Col className="lucky-loser-col">
            </Col>
            <Col className="center-parent lucky-loser-col">
                <div className="border match">
                    { name === null ? "Hier steigt ein Lucky-Loser wieder in das KO-System ein" : name }
                </div>
            </Col>
        </Row>
    );
}
