import React from 'react';
import { Container, Form, Col, InputGroup,FormControl, Button} from 'react-bootstrap';
import {DOMAIN} from '../../constants';
import {TournamentControl} from './TournamentControl';
import { ServerData } from '../../Types';



async function sendRequestToBackend(alias: string, elementId:string, token: string|null, roundId: number){
    const requestHeader = new Headers()
    if(token !== null){
        requestHeader.set('Authentication', token)
    }
    
    var element: HTMLInputElement = document?.getElementById(elementId) as HTMLInputElement;
    var data = element.value;

    const target = `${DOMAIN}/admin/` + alias + `?token=` + token + "&data=" + encodeURI(data) + "&round_id=" + roundId;

    const fetchRes = await fetch(target, {
        method: 'GET',
        headers: requestHeader
    })
    if(fetchRes.ok){
        const txt = await fetchRes.text()
        alert("Befehl gesendet an: " + target + "\nStatus: " + fetchRes.status + "\nMeldung: " + txt);
        element.value="";
    }
    else{
        const txt = await fetchRes.text()
        alert("Das hat leider nicht funktioniert.\nStatus: " + fetchRes.status + "\nFehlermeldung: " + txt);
    }
}


export function AdminPage({token, data}: {token: string|null, data: ServerData}){
    const handleClick = (alias: string, elementId: string) => sendRequestToBackend(alias, elementId, token, data.tournament.rounds.length);

    return (
        <Container>
            <TournamentControl token={token}/>
            <br/>
            <Form>
                <Form.Row className="align-items-center">
                    <Col xs="auto">
                        <InputGroup className="mb-2">
                            <InputGroup.Prepend>
                            <InputGroup.Text>Game-ID</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl id="admin-deleteGame" placeholder="e.g. TCtZMbg0" />
                        </InputGroup>
                    </Col>
                    <Col xs="auto">
                        <Button type="button" className="mb-2" onClick={() => {handleClick("delete_game", "admin-deleteGame")}}>
                            Partie-ID löschen
                        </Button>
                    </Col>
                </Form.Row>
            </Form>
            <br/>
            <br/>
            <Form>
                <Form.Row className="align-items-center">
                    <Col xs="auto">
                        <InputGroup className="mb-2">
                            <InputGroup.Prepend>
                            <InputGroup.Text>Lichess-Name</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl id="admin-setWinner" placeholder="e.g. ARoNiAder" />
                        </InputGroup>
                    </Col>
                    <Col xs="auto">
                        <Button type="button" className="mb-2" onClick={() => {handleClick("set_winner", "admin-setWinner")}}>
                             als "Winner" für Runde {data.tournament.rounds.length} setzen
                        </Button>
                        1-indexiert
                    </Col>
                </Form.Row>
            </Form>
            <br/>
            <br/>
            <Form>
                <Form.Row className="align-items-center">
                    <Col xs="auto">
                        <InputGroup className="mb-2">
                            <InputGroup.Prepend>
                            <InputGroup.Text>Lichess-Name</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl id="admin-setLuckyLoser" placeholder="e.g. ARoNiAder" />
                        </InputGroup>
                    </Col>
                    <Col xs="auto">
                        <Button type="button" className="mb-2" onClick={() => {handleClick("set_lucky_loser", "admin-setLuckyLoser")}}>
                            als Lucky Loser für Runde {data.tournament.rounds.length+1} setzen
                        </Button>
                        1-indexiert
                    </Col>
                </Form.Row>
            </Form>
            <br/>
            <br/>
            <Form>
                <Form.Group controlId="admin-setInfoText">
                    <Form.Label>Hinweistext</Form.Label>
                    <Form.Control as="textarea" rows={5} placeholder="Hallo Schachfreunde, ..." />
                    <Form.Text className="text-muted">
                        Wird mit dem nächsten data Element an alle verbundenen Apps geschickt.
                    </Form.Text>
                </Form.Group>
                <Button type="button" className="mb-2" onClick={() => {handleClick("set_info_text", "admin-setInfoText")}}>
                    als Hinweistext an alle schicken
                </Button>
            </Form>
        </Container>
    )
}