import React from 'react'
import { useParams } from "react-router-dom";
import { Container, Alert } from 'react-bootstrap';
import { REGISTER } from '../constants';
import { parseCommandLine } from 'typescript';

interface RouteParams {
    lichessName: string
}

export function Anmeldung(){
    const params = useParams<RouteParams>();

    if (params.lichessName === undefined)
        return (
            <Container>
                <h1>
                    Anmeldung zum 1. WWOO
                </h1>
                <p>
                    Hier können Sie sich für das 1. WWOO anmelden. Unser Turnier im KO-Modus mit "Lucky-Loser" wird auf dieser Seite durchgeführt. Mit dem untenstehenden Link können Sie sich hier mit Ihrem Lichess-Account anmelden. Dabei benötigen wir ihre Erlaubnis Partien für Sie auf Lichess zu erstellen.
                </p>
                <p>
                    <a href={REGISTER}>{REGISTER}</a>
                </p>
            </Container>
        )
    else
        return (
            <Container>
                <h1>
                    Herzlichen Dank für Ihre Anmeldung - {params.lichessName}
                </h1>
                <p>
                    Sie sind mit dem Lichess-Benutzernamen {params.lichessName} für das 1. WWOO registriert. Bis zum 27. ist jetzt nichts mehr zu tun.
                    Lassen Sie sich von dem "Login mit Lichess-Account"-Link in der Navigationsleiste nicht irritieren, der wird erst am 27. scharf geschaltet.
                </p>
            </Container>
        )
}