import React from 'react'
import { Modal, Button } from 'react-bootstrap';

export function LinkModal({show, link, onHide}: {show: boolean, link: string|null, onHide: any}) {
    if(link===null)
        return null;
    
    return (
        <Modal
            show={show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Die nächste Partie ist startklar
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Hier finden Sie den Link zur nächsten Runde. Viel Erfolg:
                </p>
                    <a href={link} target="_blank" rel="noreferrer">{link}</a>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onHide}>OK</Button>
            </Modal.Footer>
        </Modal>
    );
  }