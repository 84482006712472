import React from 'react';
import { Button, Table } from 'react-bootstrap';
import {DOMAIN} from '../../constants';



async function sendRequestToBackend(alias: string, token: string|null){
    const requestHeader = new Headers()
    if(token !== null){
        requestHeader.set('Authentication', token)
    }

    const target = `${DOMAIN}/admin/` + alias + `?token=` + token;

    const fetchRes = await fetch(target, {
        method: 'GET',
        headers: requestHeader
    });
    
    if(fetchRes.ok){
        const txt = await fetchRes.text()
        alert("Befehl gesendet an: " + target + "\nStatus: " + fetchRes.status + "\nMeldung: " + txt);
    }
    else{
        const txt = await fetchRes.text()
        alert("Das hat leider nicht funktioniert.\nStatus: " + fetchRes.status + "\nFehlermeldung: " + txt);
    }
}


export function TournamentControl({token}: {token: string|null}){
    const handleClick = (alias: string) => sendRequestToBackend(alias, token);

    return (
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>Einmalige Server-Aktionen</th>
                    <th>Erklärung</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><Button variant="secondary" onClick={() => handleClick("write_round")}>Write Round</Button></td>
                    <td>Die aktuellen Daten im Server werden eine csv Datei geschrieben. Die Datei wird auf dem Server unter /api/data abgelegt.</td>
                </tr>
                <tr>
                    <td><Button variant="warning" onClick={() => handleClick("next_round")}>Next Round</Button></td>
                    <td>Die Paarungen der nächsten Runde werden erstellt. Es werden noch keine Lichess-Challenges erstellt. Die Paarungen sind im Anschluss für jeden sichtbar.</td>
                </tr>
                <tr>
                    <td><Button variant="warning" onClick={() => handleClick("start_round")}>Start Round</Button></td>
                    <td>Es werden die Lichess-Challenges zur neuen Runde erstellt (ca. 1 Link pro Sekunde). Sobald die Links erstellt wurden, werden sie an die Spieler verteilt.</td>
                </tr>
                <tr>
                    <td><Button variant="warning" onClick={() => handleClick("start_clock")}>Start Clock</Button></td>
                    <td>Die Uhren aller noch nicht gestarten Partien werden in Gang gesetzt. Damit kann der Zeitplan geschützt werden.</td>
                </tr>
                <tr>
                    <td><Button variant="danger" onClick={() => handleClick("read_new")}>Read New</Button></td>
                    <td>Neue Daten werden aus der CSV Datei eingelesen. Diese Funktion ist mit großer Vorsicht zu benutzen, da die Serverdaten überschrieben werden.</td>
                </tr>
            </tbody>
        </Table>
    )
}