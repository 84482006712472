import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import {APIGame, APIMatch} from '../Types'

function Game({game, index}: {game: APIGame, index: number}){
    const gameLink = "https://lichess.org/" + game.id
    function getResult(result: number){
        switch(result){
            case 1: return "½"
            default: return (result/2).toString()
        }
    }

    if(game.result===null){
        return (
            <Button variant="success" href={gameLink} target="_blank" size="sm" className="gameButton center-parent">
                <div>
                    live
                </div>
            </Button>
        );
    }
    else if(index <= 3 || game.result !== 1)
        return (
            <Button variant="outline-secondary" href={gameLink} target="_blank" size="sm" className="gameButton center-parent" >
                <div>
                    {getResult(game.result)}
                </div>
                <div>
                    {getResult(2-game.result)}
                </div>
            </Button>
        )
    else
        return null
}

function GameTable({games}: {games: APIGame[]}){
    return (
       <div className="gameTable">
           {games.map((game, index) => (<Game game={game} index={index}/>)).filter(x => x!==null)}
       </div>
    )
}

function cssClassPlayer(match: APIMatch, player: string){
    if(match.winner===null)
        return "";
    else if (match.winner===player)
        return "winner";
    else
        return "loser";
}

export function Match({match, index}: {match: APIMatch, index: number}){
    return (
        <Container className="border match" key={index}>
            <Row>
                <Col className="name-row center-parent">
                    <div className={cssClassPlayer(match, match.player1)}>
                        {match.player1}
                    </div>
                    <div className={cssClassPlayer(match, match.player2)}>
                        {match.player2}
                    </div>
                </Col>
                <Col className="gameCol name-row">
                    <GameTable games={match.games}/>
                </Col>
            </Row>
        </Container>
    );
}
