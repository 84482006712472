import React from 'react';
import { Container, Table } from 'react-bootstrap';
import { APIPlayer } from '../Types';

export function ParticipantPage({players}: {players: APIPlayer[]}){
    return (
        <Container>
            <Table>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Spieler</th>
                        <th>DWZ</th>
                    </tr>
                </thead>
                <tbody>
                {(players === undefined || players.length === 0) ?
                    <tr><td></td><td>Es gibt leider noch keine Startliste.</td><td></td></tr> :
                    players.sort((a:APIPlayer, b:APIPlayer) => (b.rating ?? 0)-(a.rating ?? 0))
                        .map( (player, key) => (<tr className={player.isRegistered ? "isRegistered": ""}>
                            <td>{key+1}</td><td>{player.name}</td><td>{player.rating}</td></tr> ))
                }   
                </tbody>
            </Table>
        </Container>
    )
}